<template>
  <v-container>
    <v-row justify="center" class="text-subtitle-1">
      {{ indicatorTitle }}
    </v-row>
    <v-row>
      <v-col v-for="(subIndicator, i) in subIndicators" :key="i">
        <v-row justify="center" class="text-h5 font-weight-bold">
          {{ numberWithCommas(subIndicator.value) }}
        </v-row>
        <v-row justify="center" class="text-center">
          {{ subIndicator.text }}
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "NumericIndicator",
  props: {
    indicatorTitle: {
      type: String,
      required: true,
      default: "No title found.",
    },
    subIndicators: {
      type: Array,
      required: true,
    },
  },
  methods: {
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
};
</script>

<style></style>
