import Vue from "vue";
import Vuex from "vuex";
import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

// Verify state app
Vue.use(Vuex);
const state = {
  //Linked to a component
  showLocalities: false,
  selectedIndicator: null,
  selectedState: "Campeche",
  selectedLocality: null,
  selectedLocalities: false,
  myShapeFeatureLayer: null,
  selectedSite: null,
  route: null,
  currentTab: 0,
  localitiesList: null,
  sites: null,
  loadingRoute: false,
  searchedLocalities: null,
  munData: null,
  munData2024: null,
  nAgrarios: [],
};

const store = new Vuex.Store({
  state,
  actions,
  mutations,
  getters,
});
export default store;
