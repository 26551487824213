export default {
  getSearchedLocalities(state) {
    if (state.searchedLocalities) {
      return state.searchedLocalities;
    } else {
      return [];
    }
  },
  getRoute(state) {
    return state.route;
  },
  getLoadingRoute(state) {
    return state.loadingRoute;
  },
  getLocalitiesList(state) {
    if (state.localitiesList) {
      return state.localitiesList;
    } else {
      return [];
    }
  },
  getSites(state) {
    if (state.sites) {
      return state.sites;
    } else {
      return [];
    }
  },
  getSelectedIndicator(state) {
    return state.selectedIndicator;
  },
  getMyFeatureLayer(state) {
    return state.myShapeFeatureLayer;
  },
  getSelectedState(state) {
    return state.selectedState;
  },
  getSelectedSite(state) {
    return state.selectedSite;
  },
  getShowLocalities(state) {
    return state.showLocalities;
  },
  getSelectedLocality(state) {
    return state.selectedLocality;
  },
  getMunData(state) {
    return state.munData;
  },
  getMunData2024(state) {
    return state.munData2024;
  },
  getnAgrarios(state) {
    return state.nAgrarios;
  },
};
