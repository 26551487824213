<template>
  <v-card>
    <v-toolbar color="#F4BA22" class="mb-4">
      <v-toolbar-title class="text-h5 font-weight-bold">
        INDICADORES
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        color="#392f26"
        outlined
        v-if="getSelectedIndicator"
        @click="showSingle = !showSingle"
      >
        {{ showSingle ? "Mostrar todos" : "Ocultar" }}
      </v-btn>
      <v-btn icon @click="$emit('close-dialog')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <v-container>
        <v-row>
          <v-col v-for="(indicator, l) in content" :key="l" cols="12">
            <span class="text-h6 font-weight-medium">
              {{ indicator.title }}
            </span>
            <br />
            <div v-for="(text, k) in indicator.content" :key="k">
              <span
                class="text-subtitle-1 font-weight-medium"
                v-if="text.title"
              >
                {{ text.title }}
                <br />
              </span>
              <div class="pb-3">
                {{ text.text }}
              </div>
            </div>
            <div>
              <span class="text-subtitle-1 font-weight-medium">
                {{ indicator.refs.length > 1 ? "Fuentes:" : "Fuente:" }}
              </span>
              <ul>
                <li v-for="(reference, r) in indicator.refs" :key="r">
                  <span class="font-italic">{{ `${reference.name}, ` }}</span
                  >{{ reference.author }}.
                  <a :href="reference.url">
                    {{ reference.url }}
                  </a>
                </li>
              </ul>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AboutComponent",
  data() {
    return {
      showSingle: true,
      indicatorsInfo: [
        {
          title: "Población total",
          content: [
            {
              title: "",
              text: "El número total de personas por municipio, según los datos del Censo de Población y vivienda 2020.",
            },
          ],
          refs: [
            {
              name: "Censo de Población y Vivienda 2020",
              author: "Instituto Nacional de Estadística y Geografía (INEGI)",
              url: "https://inegi.org.mx/programas/ccpv/2020",
            },
          ],
        },
        {
          title: "Grado de marginación",
          content: [
            {
              title: "Economía",
              text: `
              El Grado de marginación categoriza los valores del Índice de marginación elaborado por el Consejo Nacional de Población en el 2020. Este índice mide el impacto global de las carencias que enfrenta la población en cuatro dimensiones: Ingresos monetarios, educación, vivienda y distribución de la población. Las carencias de la población son más intensas en los grados de marginación alto y muy alto.

              La Población Económicamente Activa se refiere al porcentaje de personas de 12 años y más que trabajaron, tenían trabajo pero no trabajaron o buscaron trabajo en la semana en la que se llevó a cabo el Censo de Población y Vivienda 2020.

              El Porcentaje de personas en situación de pobreza es calculado por el Consejo Nacional de Evaluación de la Política de Desarrollo Social, según el cual una persona se encuentra en situación de pobreza cuando tiene un ingreso inferior a la Línea de Pobreza por Ingresos (valor de la canasta alimentaria más la no alimentaria) y presenta al menos una de las seis carencias sociales (alimentación, rezago educativo, salud, seguridad social, servicios básicos de la vivienda, y calidad y espacios de la vivienda).
              `,
            },
            {
              title: "Educación",
              text: `Porcentaje y número de personas de 15 años y más que no saben leer ni escribir, así como el porcentaje y número de personas que no tienen escolaridad o que tienen como máxima escolaridad hasta el quinto grado aprobado en primaria.
              
              Grado Promedio de Escolaridad del total municipal, mujeres y hombres. Cada unidad representa un año escolar a partir del primer grado de educación primaria.
              `,
            },
            {
              title: "Salud",
              text: "Porcentaje y número de personas que no tienen derecho a recibir servicios médicos en ninguna institución pública o privada.",
            },
          ],
          refs: [
            {
              name: "Índices de marginación por entidad federativa y municipio 2020",
              author: "Consejo Nacional de Población (CONAPO)",
              url: "https://www.gob.mx/conapo/documentos/indices-de-marginacion-2020-284372",
            },
            {
              name: "Censo de Población y Vivienda 2020",
              author: "Instituto Nacional de Estadística y Geografía (INEGI)",
              url: "https://inegi.org.mx/programas/ccpv/2020",
            },
            {
              name: "Pobreza a nivel municipio 2010-2020",
              author:
                "Consejo Nacional de Evaluación de la Política de Desarrollo Social (CONEVAL)",
              url: "https://www.coneval.org.mx/Medicion/Paginas/Pobreza-municipio-2010-2020.aspx",
            },
          ],
        },
        {
          title: "Población afromexicana, afrodescendiente y/o negra",
          content: [
            {
              title: "",
              text: "El número y porcentaje de personas que se autoidentifican como afromexicanas, afrodescendientes y/o negras, por municipio, según los datos del Censo de Población y Vivienda 2020.",
            },
          ],
          refs: [
            {
              name: "Censo de Población y Vivienda 2020",
              author: "Instituto Nacional de Estadística y Geografía (INEGI)",
              url: "https://inegi.org.mx/programas/ccpv/2020",
            },
          ],
        },
        {
          title: "Población que habla una lengua indígena",
          content: [
            {
              title: "Lengua indígena",
              text: "El porcentaje y número de personas de 3 años y más que hablan alguna de las lenguas indígenas de México, reconocidas en la legislación del país y de las entidades federativas, según los datos del Censo de Población y Vivienda 2020. Las personas que hablan una lengua indígena, independientemente de si hablan o no español, tienen derecho a expresarse en su propio idioma ante el órgano jurisdiccional que lo está juzgando, a que éste le designe un intérprete-traductor, y a ser asistido por un defensor con conocimiento de su lengua y cultura.",
            },
            {
              title: "Lengua indígena y no español",
              text: "El número de personas de 3 años y más que hablan alguna lengua indígena de México pero que no hablan español, según los datos del Censo de Población y Vivienda 2020.",
            },
            {
              title: "Lengua indígena en el municipio",
              text: "Los nombres de las lenguas indígenas o agrupaciones lingüísticas que se hablan en el municipio: A) Principal lengua indígena, hablada por más del 40% de la población del municipio. B) Lenguas indígenas con presencia en el municipio, con menos del 40% de la población pero con más de 100 hablantes. C) Otras lenguas indígenas, con más de 10 hablantes en el municipio.",
            },
          ],
          refs: [
            {
              name: "Censo de Población y Vivienda 2020",
              author: "Instituto Nacional de Estadística y Geografía (INEGI)",
              url: "https://inegi.org.mx/programas/ccpv/2020",
            },
          ],
        },
        {
          title: "Autoadscripción indígena",
          content: [
            {
              title: "",
              text: "El porcentaje estimado de la población que se autoidentifica como indígena según el Instituto Nacional de Estadística y Geografía, para el año 2020. La autoadscripción es el criterio base para la identificación de una persona como indígena. El porcentaje de personas, a nivel municipal, que vive en un hogar donde la persona de referencia del hogar o su cónyuge hablan alguna lengua indígena, con datos del Censo de Población y Vivienda 2020.",
            },
          ],
          refs: [
            {
              name: "Censo de Población y Vivienda 2020",
              author: "Instituto Nacional de Estadística y Geografía (INEGI)",
              url: "https://inegi.org.mx/programas/ccpv/2020",
            },
          ],
        },
        {
          title: "Municipios indígenas",
          content: [
            {
              title: "",
              text: "De acuerdo con el Instituto Nacional de los Pueblos Indígenas, se considera población indígena a todas las personas que forman parte de un hogar indígena, donde el jefe o jefa del hogar, su cónyuge y/o alguno de los ascendientes declaró ser hablante de alguna lengua indígena. Además, se incluye a las personas que declararon hablar alguna lengua indígena y que no forman parte de estos hogares.Los municipios del país se categorizan con base en el porcentaje de población indígena según los criterios del INPI (2015): A) Municipio Indígena: Con el 40% y más de población indígena. B) Municipio con presencia indígena: Con menos de 40% de población indígena y más de 5,000 personas indígenas así como municipios con presencia importante de hablantes de lengua minoritaria. C) Municipio con población indígena dispersa: Con menos de 40% de población indígena. D) Municipio sin población indígena.",
            },
            {
              title: "Sistemas normativos",
              text: "En el estado de Oaxaca existen municipios que eligen autoridades municipales por Sistemas Normativos Indígenas, reconocidos en la legislación estatal. La duración del cargo puede ser por un año, un año y medio, dos años o tres años. Asimismo, como en las otras entidades del país, existen municipios que eligen autoridades municipales por el sistema de Partidos políticos, con duración en el cargo por tres años.",
            },
            {
              title: "Núcleos Agrarios",
              text: "Los tipos de tenencia de la tierra en el municipio, según el Registro Agrario Nacional y los nombres de los núcleos agrarios correspondientes a cada municipio y su estatus de certificación.",
            },
          ],
          refs: [
            {
              name: "Indicadores de la población indígena 2015",
              author: "Instituto Nacional de los Pueblos Indígenas.",
              url: "https://www.gob.mx/inpi/documentos/indicadores-de-la-poblacion-indigena",
            },
            {
              name: "Catálogo de municipios sujetos al régimen de Sistemas Normativos Indígenas 2018",
              author: "IEEPCO",
              url: "https://www.ieepco.org.mx/sistemas-normativos/municipios-sujetos-al-regimen-de-sistemas-normativos-indigenas-2018",
            },
            {
              name: "Catálogo de núcleos agrarios de la propiedad social 2019",
              author: "Registro Agrario Nacional.",
              url: "",
            },
            {
              name: "Listado de núcleos agrarios certificados 2019, Series Estadísticas",
              author: "Registro Agrario Nacional",
              url: "https://datos.ran.gob.mx/conjuntoDatosPublico.php",
            },
          ],
        },
        {
          title: "Adolescentes",
          content: [
            {
              title: "",
              text: "El número de personas de 12 a 17 años, total municipal, mujeres y hombres, según los datos del Censo de Población y Vivienda 2020.",
            },
          ],
          refs: [
            {
              name: "Censo de Población y Vivienda 2020",
              author: "Instituto Nacional de Estadística y Geografía (INEGI)",
              url: "https://inegi.org.mx/programas/ccpv/2020",
            },
          ],
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["getSelectedIndicator"]),
    content() {
      if (this.getSelectedIndicator && this.showSingle) {
        return this.getSingleContent(this.getSelectedIndicator);
      } else return this.indicatorsInfo;
    },
  },
  methods: {
    getSingleContent(indicatorTitle) {
      return this.indicatorsInfo.filter(
        (indicator) => indicator.title === indicatorTitle
      );
    },
  },
};
</script>
