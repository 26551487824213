<template>
  <v-container>
    <v-row class="text-subtitle-1"> {{ text }}</v-row>
  </v-container>
</template>

<script>
export default {
  name: "TextIndicator",
  props: {
    text: {
      type: String,
      required: true,
    },
  },
};
</script>
