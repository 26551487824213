<template>
  <v-container v-if="munData && selectedIndicator">
    <v-divider></v-divider>
    <!-- POB_TOT Y POB_AFRO -->
    <v-row v-if="selectedIndicator === 'Población total'">
      <v-col>
        <v-card flat>
          <v-card-title>
            <span class="font-weight-light"> Datos del municipio / </span>
            <span class="font-weight-medium">{{ munData.nom_mun }}</span>
          </v-card-title>
          <v-card-text>
            <NumericIndicator
              :indicatorTitle="selectedIndicator"
              :subIndicators="pobTotArray"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row
      v-else-if="
        selectedIndicator === 'Población que habla una lengua indígena'
      "
    >
      <v-col>
        <v-card flat>
          <v-tabs
            background-color="#F5F5F5"
            color="basil"
            hide-slider
            show-arrows
            active-class="tabs"
            v-model="tab"
          >
            <v-tab v-for="item in speakerTabs" :key="item">
              <div style="width: 140px">
                {{ item }}
              </div>
            </v-tab>
          </v-tabs>
          <v-card-title>
            <span class="font-weight-light"> Datos del municipio / </span>
            <span class="font-weight-medium">{{ munData.nom_mun }}</span>
          </v-card-title>
          <v-card-text>
            <v-tabs-items v-model="tab">
              <v-tab-item>
                <NumericIndicator
                  indicatorTitle="Población que habla una lengua indígena"
                  :subIndicators="speakerTotal"
                />
                <v-divider class="my-2"></v-divider>
                <NumericIndicator
                  indicatorTitle="Población de 3 años o más que habla una lengua indígena"
                  :subIndicators="speakerThreeYears"
                />
              </v-tab-item>
              <v-tab-item>
                <NumericIndicator
                  indicatorTitle="Población de 3 años o más que habla una lengua indígena y no habla español"
                  :subIndicators="noSpanish"
                />
              </v-tab-item>
              <v-tab-item>
                <ListIndicator
                  list-title="Principal lengua indígena hablada en el municipio"
                  :items-list="li1"
                />
                <v-divider class="my-2"></v-divider>
                <ListIndicator
                  list-title="Lenguas indígenas con presencia en el municipio"
                  :items-list="li2"
                />
                <v-divider class="my-2"></v-divider>
                <ListIndicator
                  list-title="Otras lenguas indígenas"
                  :items-list="li3"
                />
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row
      v-else-if="
        selectedIndicator ===
        'Población afromexicana, afrodescendiente y/o negra'
      "
    >
      <v-col>
        <v-card flat>
          <v-card-title>
            <span class="font-weight-light"> Datos del municipio / </span>
            <span class="font-weight-medium">{{ munData.nom_mun }}</span>
          </v-card-title>
          <v-card-text>
            <NumericIndicator
              indicatorTitle="Población afromexicana, afrodescendiente y/o negra"
              :subIndicators="pobAfroArray"
            />
            <v-divider class="my-2"></v-divider>
            <NumericIndicator
              indicatorTitle=""
              :subIndicators="pobAfroPercentage"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-else-if="selectedIndicator === 'Autoadscripción indígena'">
      <v-col>
        <v-card flat>
          <v-card-title>
            <span class="font-weight-light"> Datos del municipio / </span>
            <span class="font-weight-medium">{{ munData.nom_mun }}</span>
          </v-card-title>
          <v-card-text>
            <NumericIndicator
              indicatorTitle="Población que se autoadscribe como indígena"
              :subIndicators="autoadscriptionArray"
            />
            <v-divider class="my-2"></v-divider>
            <NumericIndicator
              indicatorTitle="Porcentaje de la población en hogares indígenas"
              :subIndicators="indigenousHomesArray"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-else-if="selectedIndicator === 'Municipios indígenas'">
      <v-col>
        <v-card flat>
          <v-tabs
            background-color="#F5F5F5"
            color="basil"
            hide-slider
            show-arrows
            grow
            active-class="tabs"
            v-model="tab"
          >
            <v-tab v-for="item in munTabs" :key="item">
              <div style="width: 140px">
                {{ item }}
              </div>
            </v-tab>
          </v-tabs>
          <v-card-title>
            <span class="font-weight-light"> Datos del municipio / </span>
            <span class="font-weight-medium">{{ munData.nom_mun }}</span>
          </v-card-title>
          <v-card-text>
            <v-tabs-items v-model="tab">
              <v-tab-item>
                <TextIndicator :text="`Municipios con ${munData.sn_ind}`" />
              </v-tab-item>
              <v-tab-item>
                <TextIndicator
                  :text="`Tipos de tenencia de la tierra en el municpio ${munData.tent}`"
                />
                <v-divider class="my-2"></v-divider>
                <ListIndicator
                  listTitle="Núcleos agrarios en el municipio"
                  :itemsList="nAgrarios"
                />
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-else-if="selectedIndicator === 'Grado de marginación'">
      <v-col>
        <v-card flat>
          <v-tabs
            background-color="#F5F5F5"
            color="basil"
            grow
            hide-slider
            active-class="tabs"
            v-model="tab"
          >
            <v-tab v-for="item in marginalizationTabs" :key="item">
              {{ item }}
            </v-tab>
          </v-tabs>
          <v-card-title>
            <span class="font-weight-light"> Datos del municipio / </span>
            <span class="font-weight-medium">{{ munData.nom_mun }}</span>
          </v-card-title>
          <v-card-text>
            <TextIndicator
              :text="`Grado de marginación del municipio: ${munData.gmm_20}`"
            />
            <v-divider class="my-1"></v-divider>
            <v-tabs-items v-model="tab">
              <v-tab-item>
                <NumericIndicator
                  indicatorTitle="Población de 12 años y más que es economicamente activa"
                  :subIndicators="ecomomicActiveArray"
                />
                <v-divider class="my-2"></v-divider>
                <NumericIndicator
                  indicatorTitle="Porcentaje de la población que está en situación de pobreza"
                  :subIndicators="povertyArray"
                />
              </v-tab-item>
              <v-tab-item>
                <NumericIndicator
                  indicatorTitle="Población de 15 años y más que es analfabeta"
                  :subIndicators="illiteratePopulationArray"
                />
                <v-divider class="my-2"></v-divider>
                <NumericIndicator
                  indicatorTitle="Población de 15 años o más sin escolaridad o con primaria incompleta."
                  :subIndicators="uncompletedElementaryArray"
                />  
                <v-divider class="my-2"></v-divider>
                <NumericIndicator
                  indicatorTitle="Grado Promedio de Escolaridad"
                  :subIndicators="averageScholarArray"
                />
              </v-tab-item>
              <v-tab-item>
                <NumericIndicator
                  indicatorTitle="Población sin afiliación a servicios de salud"
                  :subIndicators="noHealthInsuranceArray"
                />
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-else-if="selectedIndicator === 'Adolescentes'">
      <v-col>
        <v-card flat>
          <v-card-title>
            <span class="font-weight-light"> Datos del municipio / </span>
            <span class="font-weight-medium">{{ munData.nom_mun }}</span>
          </v-card-title>
          <v-card-text>
            <NumericIndicator
              indicatorTitle="Población de 12 a 17 años"
              :subIndicators="pobAdolescentes"
            />
            <v-divider class="my-2"></v-divider>
                <NumericIndicator
                  indicatorTitle="Población de 12 a 17 años que habla una lengua indígena"
                  :subIndicators="speakerTwelveYears"
                />
            <v-divider class="my-2"></v-divider>
            <NumericIndicator
              indicatorTitle="Población de 12 a 17 años, afromexicana, afrodescendiente y/o negra"
              :subIndicators="pobAfroTwelveArray"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import NumericIndicator from "./NumericIndicator.vue";
import TextIndicator from "./TextIndicator.vue";
import ListIndicator from "./ListIndicator.vue";
import { mapGetters } from "vuex";

export default {
  name: "IndicatorsController",
  components: {
    NumericIndicator,
    TextIndicator,
    ListIndicator,
  },
  data() {
    return {
      tab: 0,
      speakerTabs: [
        "Lengua Indígena",
        "Lengua Indígena y no español",
        "Lengua indígena en el Municipio",
      ],
      munTabs: ["Sistemas Normativos", "Núcleos Agrarios"],
      marginalizationTabs: ["Economía", "Educación", "Salud"],
    };
  },
  computed: {
    ...mapGetters({
      selectedIndicator: "getSelectedIndicator",
      munData: "getMunData",
      munData2024: "getMunData2024",
      nAgrarios: "getnAgrarios",
    }),
    afroOrTot() {
      if (
        this.selectedIndicator === "Población total" ||
        this.selectedIndicator ===
          "Población afromexicana, afrodescendiente y/o negra"
      ) {
        return true;
      } else {
        return false;
      }
    },
    illiteratePopulationArray() {
      if (this.selectedIndicator === "Grado de marginación") {
        return [
          {
            text: "Porcentaje Total Municipal",
            value: `${this.munData.F_p15ym_an}%`,
          },
          { text: "Mujeres", value: this.munData.p15ym_an_f },
          { text: "Hombres", value: this.munData.p15ym_an_m },
        ];
      } else {
        return [];
      }
    },
    uncompletedElementaryArray() {
      if (this.selectedIndicator === "Grado de marginación") {
        return [
          {
            text: "Porcentaje Total Municipal",
            value: `${this.munData.F_p15pri_in}%`,
          },
          { text: "Mujeres", value: this.munData.p15pri_inf },
          { text: "Hombres", value: this.munData.p15pri_inm },
        ];
      } else {
        return [];
      }
    },
    averageScholarArray() {
      console.log('averageScholarArray', this.selectedIndicator, this.selectedIndicator === "Grado de marginación", this.munData)
      if (this.selectedIndicator === "Grado de marginación") {
        
        return [
          {
            text: "Total Municipal",
            value: this.munData.graproes,
          },
          { text: "Mujeres", value: this.munData.graproes_f},
          { text: "Hombres", value: this.munData.graproes_m },
        ];
      } else {
        return [];
      }
    },
    noHealthInsuranceArray() {
      if (this.selectedIndicator === "Grado de marginación") {
        return [
          {
            text: "Porcentaje Total Municipal",
            value: `${this.munData.F_psinder}%`,
          },
          { text: "Mujeres", value: this.munData.psinder_f },
          { text: "Hombres", value: this.munData.psinder_m },
        ];
      } else {
        return [];
      }
    },
    ecomomicActiveArray() {
      if (this.selectedIndicator === "Grado de marginación") {
        return [
          {
            text: "Porcentaje Total Municipal",
            value: `${this.munData.F_pea}%`,
          },
          { text: "Mujeres", value: `${this.munData.F_pea_f}%` },
          { text: "Hombres", value: `${this.munData.F_pea_m}%` },
        ];
      } else {
        return [];
      }
    },
    povertyArray() {
      if (this.selectedIndicator === "Grado de marginación") {
        return [
          {
            text: "Porcentaje Total Municipal",
            value: `${this.munData.F_pobreza}%`,
          },
        ];
      } else {
        return [];
      }
    },
    pobTotArray() {
      if (this.selectedIndicator === "Población total") {
        return [
          { text: "Total", value: this.munData.pobtot },
          { text: "Mujeres", value: this.munData.pobfem },
          { text: "Hombres", value: this.munData.pobmas },
        ];
      } else {
        return [];
      }
    },
    pobAdolescentes() {
      if (this.selectedIndicator === "Adolescentes") {
        return [
          { text: "Total", value: this.munData.p_12_a_17 },
          { text: "Mujeres", value: this.munData.p_12_a_17_f},
          { text: "Hombres", value: this.munData.p_12_a_17_m },
        ];
      } else {
        return [];
      }
    },
    pobAfroArray() {
      if (
        this.selectedIndicator ===
        "Población afromexicana, afrodescendiente y/o negra"
      ) {
        return [
          { text: "Total", value: this.munData.pob_afro },
          { text: "Mujeres", value: this.munData.pob_afro_f },
          { text: "Hombres", value: this.munData.pob_afro_m },
        ];
      } else {
        return [];
      }
    },
    pobAfroPercentage() {
      if (
        this.selectedIndicator ===
        "Población afromexicana, afrodescendiente y/o negra"
      ) {
        return [
          {
            text: "Porcentaje Total Municipal",
            value: `${this.munData.F_pob_afro}%`,
          },
        ];
      } else {
        return [];
      }
    },
    pobAfroTwelveArray() {
      if (
        this.selectedIndicator ===
        "Adolescentes"
      ) {
        return [
          { text: "Total", value: this.munData.afro_12_a_17 },
          { text: "Mujeres", value: this.munData.afro_12_a_17_f },
          { text: "Hombres", value: this.munData.afro_12_a_17_m },
          { text: "%", value: this.munData.F__afro_adol },
        ];
      } else {
        return [];
      }
    },
    autoadscriptionArray() {
      if (this.selectedIndicator === "Autoadscripción indígena") {
        return [
          {
            text: "Porcentaje Total Municipal",
            value: `${this.munData.F__aai}%`,
          },
          { text: "Porcentaje de Mujeres", value: `${this.munData.aai_f}%` },
          { text: " Porcentaje de Hombres", value: `${this.munData.aai_m}%` },
        ];
      } else {
        return [];
      }
    },
    indigenousHomesArray() {
      if (this.selectedIndicator === "Autoadscripción indígena") {
        return [
          {
            text: "Porcentaje Total Municipal",
            value: `${this.munData.F__aai}%`,
          },
        ];
      } else {
        return [];
      }
    },
    speakerTotal() {
      if (
        this.selectedIndicator === "Población que habla una lengua indígena"
      ) {
        return [
          {
            text: "Porcentaje Total Municipal",
            value: `${this.munData.F__hli}%`,
          },
        ];
      } else {
        return [];
      }
    },
    speakerThreeYears() {
      if (
        this.selectedIndicator === "Población que habla una lengua indígena"
      ) {
        return [
          {
            text: "Total Municipal",
            value: `${this.munData.p3ym_hli}`,
          },
          {
            text: "Mujeres",
            value: `${this.munData.p3ym_hli_f}`,
          },
          {
            text: "Hombres",
            value: `${this.munData.p3ym_hli_m}`,
          },
        ];
      } else {
        return [];
      }
    },
    speakerTwelveYears() {
      if (
        this.selectedIndicator === "Adolescentes"
      ) {
        return [
          {
            text: "Total Municipal",
            value: `${this.munData.hli__12_a_17}`,
          },
          {
            text: "Mujeres",
            value: `${this.munData.hli__12_a_17_f}`,
          },
          {
            text: "Hombres",
            value: `${this.munData.hli__12_a_17_m}`,
          },
          {
            text: "%",
            value: `${this.munData.F__hli_12_a_17}`,
          },
        ];
      } else {
        return [];
      }
    },
    noSpanish() {
      if (
        this.selectedIndicator === "Población que habla una lengua indígena"
      ) {
        return [
          {
            text: "Total Municipal",
            value: `${this.munData.p3hlinhe}`,
          },
          {
            text: "Mujeres",
            value: `${this.munData.p3hlinhe_f}`,
          },
          {
            text: "Hombres",
            value: `${this.munData.p3hlinhe_m}`,
          },
        ];
      } else {
        return [];
      }
    },
    li1() {
      if (
        this.selectedIndicator === "Población que habla una lengua indígena" &&
        this.munData &&
        this.munData.li1 &&
        this.munData.li1.trim().length > 0
      ) {
        let languages = this.munData.li1.split(", ");
        return languages;
      } else {
        return ["N/A"];
      }
    },
    li2() {
      if (
        this.selectedIndicator === "Población que habla una lengua indígena" &&
        this.munData &&
        this.munData.li2 &&
        this.munData.li2.trim().length > 0
      ) {
        let languages = this.munData.li2.split(",");
        return languages;
      } else {
        return ["N/A"];
      }
    },
    li3() {
      if (
        this.selectedIndicator === "Población que habla una lengua indígena" &&
        this.munData &&
        this.munData.li3 &&
        this.munData.li3.trim().length > 0
      ) {
        let languages = this.munData.li3.split(",");
        return languages;
      } else {
        return ["N/A"];
      }
    },
  },
};
</script>

<style scoped>
.tabs {
  background-color: #f4ba22;
}
</style>
