<template>
  <v-app>
    <v-main>
      <Header />
      <Map />
      <MapOptions />
    </v-main>
  </v-app>
</template>

<script>
import Header from "./components/Header";
import MapOptions from "./components/MapOptions";
import Map from "./components/Map";

export default {
  name: "App",

  components: {
    Header,
    MapOptions,
    Map,
  },
  data: () => ({
    //
  }),
};
</script>
