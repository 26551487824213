<template>
  <v-card outlined elevation="7" tile class="card" width="450px">
    <!-- TABS FEATURES -->
    <v-tabs
      v-model="tab"
      background-color="#F5F5F5"
      color="basil"
      grow
      hide-slider
      active-class="tabs"
    >
      <v-tab v-for="item in mapFunctionalities" :key="item">
        {{ item }}
      </v-tab>
    </v-tabs>
    <!-- TABS ESTADOS -->
    <v-tabs
      fixed-tabs
      slider-color="#F4BA22"
      color="#666666"
      slider-size="5"
      v-model="selectedState"
    >
      <v-tab
        v-for="state in states"
        :key="state"
        class="title-spec"
        @change="switchStatesIndicators(state)"
      >
        {{ state }}
      </v-tab>
    </v-tabs>
    <!-- SEARCHER -->
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-autocomplete
            v-model="selectedLocality"
            :items="searchedLocalities"
            :search-input.sync="searchLoc"
            hide-details
            hide-no-data
            hide-selected
            item-text="text"
            outlined
            dense
            label="Buscar localidad"
            append-icon=""
            return-object
            @change="setSelectedLocality(selectedLocality)"
          ></v-autocomplete>
        </v-col>
      </v-row>
    </v-container>
    <v-tabs-items v-model="tab">
      <!-- INDICADORES -->
      <v-tab-item>
        <v-card color="basil" flat>
          <v-container fluid class="pb-0">
            <v-row>
              <v-col cols="10" class="pr-0">
                <v-autocomplete
                  v-model="selectedIndicator"
                  id="field-select"
                  :items="pIndicators"
                  label="Elige un indicador"
                  outlined
                  dense
                  hide-details
                  @change="selectIndicator(selectedIndicator)"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="2" class="">
                <v-btn icon @click="info = !info">
                  <v-icon color="#F4BA22" size="30px">
                    mdi-information-outline
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row class="mb-3">
              <v-switch
                v-model="showLegends"
                class="mt-0 ml-2"
                dense
                hide-details
                color="success"
                label="Mostrar leyendas"
              />
            </v-row>
            <v-row>
              <IndicatorsController />
            </v-row>
            <v-row>
              <v-divider></v-divider>
            </v-row>
            <v-row>
              <v-col>
                <v-autocomplete
                  v-model="selectedSite"
                  :items="lawSites"
                  label="Elige un sitio de impartición de justicia"
                  outlined
                  dense
                  hide-details
                  @change="selectSite(selectedSite)"
                />
              </v-col>
            </v-row>
            <div class="my-3 d-flex justify-space-around align-center">
              <v-img
                max-width="20"
                max-height="20"
                class="mx-5"
                src="/localidades.svg"
              ></v-img>
              <v-switch
                label="Localidades por distancia de juzgado"
                class="mt-0"
                dense
                hide-details
                v-model="showLocalities"
                color="success"
                @change="switchLocalities(showLocalities)"
              />
            </div>
          </v-container>
        </v-card>
      </v-tab-item>
      <!-- Distancia -->
      <v-tab-item>
        <v-card color="basil" flat>
          <v-container>
            <v-row>
              <v-col>
                <v-autocomplete
                  v-model="selectedSite"
                  :items="lawSites"
                  label="Elige un sitio de impartición de justicia"
                  outlined
                  dense
                  auto-select-firstgit
                  @change="selectSite(selectedSite)"
                >
                </v-autocomplete>
                <v-autocomplete
                  :disabled="!isSiteSelected"
                  v-model="selectedLawSite"
                  :items="sites"
                  item-text="text"
                  outlined
                  dense
                  return-object
                  label="Selecciona una entidad jurídica"
                  @change="lawSiteSelected(selectedLawSite)"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
    <!-- DISTANCE INFO -->
    <v-card
      v-if="$store.getters.getRoute"
      tile
      style="border-left: 15px solid #78c36f"
      class="ml-0"
    >
      <v-container>
        <v-row>
          <v-col cols="8">
            <v-icon>mdi-map-marker-distance</v-icon> Distancia:
            <span
              class="text-subtitle-1 grey--text text--darken-2 font-weight-bold"
            >
              {{ $store.getters.getRoute.km }} km</span
            >
            <br />
            <v-icon>mdi-clock-time-four-outline</v-icon> Tiempo:
            <span
              class="text-subtitle-1 grey--text text--darken-2 font-weight-bold"
            >
              {{ $store.getters.getRoute.time }} hrs</span
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-progress-circular
              v-if="loadingRoute"
              indeterminate
              color="#F4BA22"
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-dialog v-model="info" max-width="800px" scrollable>
      <AboutComponent @close-dialog="info = false" />
    </v-dialog>
  </v-card>
</template>

<script>
import AboutComponent from "./AboutComponent.vue";
import IndicatorsController from "./IndicatorsController.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "MapOptions",
  components: {
    AboutComponent,
    IndicatorsController,
  },
  data() {
    return {
      showLegends: true,
      indicatorsLocalities: [1, 2, 3],
      selectedLocality: null,
      info: false,
      isLoading: false,
      searchVal: "",
      searchLoc: "",
      searchLocDistance: "",
      selectedLawSite: null,
      showLocalities: false,
      selectedIndicator: null,
      tab: 0,
      selectedState: 0,
      selected_theme: null,
      selectedLocalities: null,
      selectedSite: null,
      mapFunctionalities: ["INDICADORES", "DISTANCIA"],
      states: ["Campeche", "Oaxaca", "Quintana Roo","Yucatán"],
      topics: ["Población", "Población indígena"],
      pIndicators: [
        "Población total",
        "Grado de marginación",
        "Población afromexicana, afrodescendiente y/o negra",
        "Población que habla una lengua indígena",
        "Autoadscripción indígena",
        "Municipios indígenas",
        "Adolescentes"
      ],
      lawSites: [
        "Centros penitenciarios",
        "Ministerios públicos estatales",
        "Ministerios públicos federales",
        "Tribunales y juzgados estatales",
        "Tribunales y juzgados federales",
        "Justicia para adolescentes",
        "Juzgados de atención a población indígena",
      ],
    };
  },
  watch: {
    showLegends(val) {
      this.$root.$emit("changeShowLegends", val);
    },
    selectedLocality(val) {
      if (val === null) {
        this.$store.dispatch("setMunData", null);
        this.$store.dispatch("setMunData2024", null);
        this.$store.dispatch("setSelectedLocality", null);
      }
    },
    selectedIndicator(val) {
      if (val === null) {
        this.$store.dispatch("selectIndicator", null);
      }
    },
    tab(val) {
      this.setCurrentTab(val);
      if (val === 1) {
        this.showLocalities = true;
        this.$root.$emit("toggleMode", this.selectedState);
        this.switchLocalities(this.showLocalities);
      }
    },
    searchVal() {
      if (this.searchVal && this.searchVal.length > 2) {
        this.$root.$emit("run-query", this.searchVal);
      }
    },
    searchLoc() {
      if (this.searchLoc && this.searchLoc.length > 2) {
        this.$root.$emit("search-loc", this.searchLoc);
      }
    },
    searchLocDistance() {
      if (this.searchLocDistance && this.searchLocDistance.length > 2) {
        this.$root.$emit("search-loc", this.searchLocDistance);
      }
    },
  },
  computed: {
    ...mapGetters({
      localities: "getLocalitiesList",
      searchedLocalities: "getSearchedLocalities",
      sites: "getSites",
      loadingRoute: "getLoadingRoute",
    }),
    isSiteSelected() {
      if (this.selectedSite) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    ...mapActions([
      "setSearchedLocalities",
      "switchLocalities",
      "selectState",
      "selectSite",
      "selectIndicator",
      "setCurrentTab",
      "setSelectedLocality",
    ]),
    lawSiteSelected(lawSite) {
      this.$root.$emit("lawSiteSelected", lawSite);
    },
    localitySelected(loc) {
      this.$root.$emit("localitySelected", loc);
    },
    //Create a function that is called only 1 time ( to switch dropdowns options in autocomplete )
    switchStatesIndicators(selectedState) {
      this.selectedLocality = null;
      this.selectedLawSite = null;
      this.selectedIndicator = null;
      this.selectState(selectedState);
      this.setSearchedLocalities(null);
      this.$root.$emit("changedSelectedState");
    },
  },
};
</script>

<style scoped>
h1 {
  font-family: "Open Sans", sans-serif;
  font-size: 11.5px;
  text-align: center;
  font-weight: 500;
  margin-bottom: 0;
}
h2 {
  font-family: "Open Sans", sans-serif;
  font-size: 11px;
  font-weight: 300;
  text-align: center;
}
h3 {
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
}
.card {
  float: right;
  margin-top: 1.5%;
  margin-right: 1.5%;
}
.tabs {
  background-color: #f4ba22;
}
.title-spec {
  text-transform: capitalize;
}
.spacer {
  display: flex;
  justify-content: space-around;
}
.localidades {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 15px;
  margin-bottom: 25px;
}
.top {
  margin-top: 30px;
}
</style>
