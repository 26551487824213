export default {
  setSearchedLocalities(state, payload) {
    state.searchedLocalities = payload;
  },
  toggleLoadingRoute(state) {
    state.loadingRoute = !state.loadingRoute;
  },
  setSites(state, sites) {
    state.sites = sites;
  },
  setLocalities(state, payload) {
    state.localitiesList = payload;
  },
  //Handle data ( Set the layer in Feature Layer )
  updateFeatureLayer(state, layer) {
    state.myShapeFeatureLayer = layer;
  },

  //Handle data ( Set the points localities on the map ) "Localidades"
  switchLocalities(state, showLocalities) {
    //Show Localities in the parameter to state
    state.showLocalities = showLocalities;
  },

  //Handle data ( Select states to show )
  selectState(state, selectedState) {
    state.selectedState = selectedState;
  },
  //Handle data ( Select points sites to show ) "centros penitenciarios"
  selectSite(state, selectedSite) {
    state.selectedSite = selectedSite;
  },
  //Handle data ( Every time when select option in dropdown )
  selectIndicator(state, selectedIndicator) {
    state.selectedIndicator = selectedIndicator;
  },
  setRouteData(state, payload) {
    state.route = payload;
  },
  setCurrentTab(state, payload) {
    state.currentTab = payload;
  },

  setSelectedLocality(state, selectedLocality) {
    state.selectedLocality = selectedLocality;
  },
  setMunData(state, data) {
    state.munData = data;
  },
  setMunData2024(state, data) {
    state.munData2024 = data;
  },
  setnAgrarios(state, data) {
    state.nAgrarios = data;
  },
};
