export default {
  setSearchedLocalities({ commit }, payload) {
    commit("setSearchedLocalities", payload);
  },

  toggleLoadingRoute({ commit }) {
    commit("toggleLoadingRoute");
  },

  setSites({ commit }, sites) {
    commit("setSites", sites);
  },

  setLocalities({ commit }, payload) {
    commit("setLocalities", payload);
  },

  //Handle data ( Set the layer in Feature Layer )
  updateFeatureLayer({ commit }, layer) {
    commit("updateFeatureLayer", layer);
  },

  //Handle data ( Set the points localities on the map ) "Localidades"
  switchLocalities({ commit }, showLocalities) {
    //Show Localities in the parameter to state
    commit("switchLocalities", showLocalities);
  },

  //Handle data ( Select states to show )
  selectState({ commit }, selectedState) {
    commit("selectState", selectedState);
  },

  //Handle data ( Select points sites to show ) "centros penitenciarios"
  selectSite({ commit }, selectedSite) {
    commit("selectSite", selectedSite);
  },

  //Handle data ( Every time when select option in dropdown )
  selectIndicator({ commit }, selectedLayer) {
    commit("selectIndicator", selectedLayer);
  },

  setRouteData({ commit }, payload) {
    commit("setRouteData", payload);
  },

  setCurrentTab({ commit }, payload) {
    commit("setCurrentTab", payload);
  },

  setSelectedLocality({ commit }, selectedLocality) {
    commit("setSelectedLocality", selectedLocality);
  },
  setMunData({ commit }, data) {
    commit("setMunData", data);
  },
  setMunData2024({ commit }, data) {
    commit("setMunData2024", data);
  },
  setnAgrarios({ commit }, data) {
    commit("setnAgrarios", data);
  },
};
