<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="px-0 pb-0">
        <h4 class="text-subtitle-1">
          {{ listTitle }}
        </h4>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="px-0">
        <v-virtual-scroll
          :items="itemsList"
          item-height="40"
          max-height="150"
          min-height="30"
        >
          <template v-slot:default="{ item }">
            <v-list-item dense class="px-0">
              <v-list-item-content class="text-caption">
                {{ item }}
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
          </template>
        </v-virtual-scroll>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ListIndicator",
  props: {
    listTitle: {
      type: String,
      required: true,
    },
    itemsList: {
      type: Array,
      required: true,
    },
  },
};
</script>
